import { autoinject, observable } from 'aurelia-framework';

import { BasicForm } from "../../resources/elements/BasicForm";
import { I18N } from "aurelia-i18n";
import { ConfigService } from "../../resources/services/ConfigService";
import { PatientService } from 'resources/services/PatientService';
import { ModalBodyMap } from "resources/elements/modal-body-map";
import { Modal3dBody } from "resources/elements/modal-3dbody";
import * as environment from "../../../config/environment.json";
import { FhirService } from "../../resources/services/FhirService";
import { UserService } from "../../resources/services/UserService";
import { DialogService } from 'aurelia-dialog';
import {Router} from "aurelia-router";
import { QuestionnaireDialogContainer } from 'resources/elements/questionnaire/questionnaire-dialog-container';
import { QuestionnaireService } from 'resources/services/QuestionnaireService';
import { IFormSetting } from 'resources/classes/IFormSettings';
import { QuestionnaireResponse } from 'resources/classes/FhirModules/QuestionnaireResponse';
import {translations} from "resources/classes/translations";
import { fhirEnums } from 'resources/classes/fhir-enums';
import * as Fhir from "resources/classes/FhirModules/Fhir";
import { PermissionService } from 'resources/services/PermissionService';

@autoinject
export class PatientCurve {
    @observable patient;
    patientService: PatientService;
    i18n: I18N;
    userService: UserService;
    dialogService: DialogService;
    router: Router;
    fhirService: FhirService;
    permissionService: PermissionService;
    config;
    encounterId: string = undefined;
    container;
    iframeUrl = '';
    iframeContainer;
    iframeOrigin;
    iframeListener;
    is3dBody: boolean = false;

    constructor(i18n: I18N, patientService: PatientService, userService: UserService, dialogService: DialogService, router: Router, fhirService: FhirService, permissionService: PermissionService) { 
        this.patientService = patientService;
        this.i18n = i18n;
        this.userService = userService;
        this.dialogService = dialogService;
        this.router = router;
        this.fhirService = fhirService;
        this.permissionService = permissionService;

        const config = ConfigService.GetFormSettings(ConfigService.FormNames.Wounds);
        this.is3dBody = Boolean(config.settings?.body3d?.enabled);

        this.iframeListener = (event) => {
            if (event.origin === this.iframeOrigin) {
                const data = event.data.data;

                switch (event.data.name) {
                    case 'action': {
                        if (data === "graphixx") {
                            this.dialogService
                                .open({
                                    viewModel: this.is3dBody ? Modal3dBody : ModalBodyMap,
                                    model: {
                                        patient: this.patient,
                                    },
                                    centerHorizontalOnly: this.is3dBody,
                                })
                                .whenClosed((result) => {
                                    if (!result.wasCancelled) {
                                    }
                                });
                        }
                        break;
                    }
                    case 'openQuestionnaireResponse': {
                        this.router.navigate(`#/encounter/${this.encounterId}/questionnaire/${data.qId}/${data.qName}/${data.qrId}`);
                        break;
                    }
                    case 'openQuestionnaireDialog': {
                        this.openModalAnalyseWindow(data.qId, data.qrId);
                        break;
                    }
                }
            }
        };
    }

    protected async openModalAnalyseWindow(questionnaireId, questionnaireResponseId) {
        if (this.patient.isOffline) return;
        const currentTitle = BasicForm.pageTitle;

        const currentQuestionnaire = await QuestionnaireService.GetQuestionnaireById(questionnaireId);
        const currentResponse = await this.fhirService.get(`QuestionnaireResponse/${questionnaireResponseId}`);
        const currentStatus = translations.translate(currentResponse.status);

        const dialogSettings = {
            grouplist: false,
            response: currentResponse,
            questionnaire: currentQuestionnaire,
            encounter: this.patient.encounter,
            tooold: false,
            haschanges: false,
            dialogService: this.dialogService,
            status: currentStatus,
            saveText: this.i18n.tr("save"),
            abortText: this.i18n.tr("abort"),
            patientService: this.patientService,
            allowAddNew: false,
            showSelection: false,
            showToolbar: false,
            removeNoToolbarWindow: false,
            needsEditButton: true,
            advancedSaveButton: true,
            data: {
                title: currentQuestionnaire.title || currentQuestionnaire.name
            }
        };

        return this.dialogService
            .open({viewModel: QuestionnaireDialogContainer, model: dialogSettings, lock: true})
            .whenClosed(async (result) => {
                BasicForm.pageTitle = currentTitle;

                if (!result.wasCancelled) {
                    await this.fhirService.update(result.output);
                    this.iframeContainer.contentWindow.postMessage({
                        name: 'questionnaire-dialog-closed',
                        data: null
                    }, this.iframeUrl);
                }
            })
            .catch(e => {
                console.warn(e);
            });
    }

    async activate(params) {
        this.encounterId = params.id;
    }

    detached() {
        document.body.classList.remove("no-toolbar-window");
        document.body.classList.remove("hide-process-arrows");

        window.removeEventListener("message", this.iframeListener);
    }

    async attached() {
        this.config = ConfigService.GetFormSettings(ConfigService.FormNames.TherapyOverview);
        this.patient = await this.patientService.fetch(this.encounterId);

        const loginData = sessionStorage.getItem(environment.sessionName);

        const query:any = {
            login: loginData,
            server: FhirService.Endpoint,
            origin: window.location.origin,
            encounterId: this.patient.encounterId,
            patientId: this.patient.id,
            practitionerId: ''
        };

        if (this.config.settings.iframe.layout) {
            query.layout = this.config.settings.iframe.layout;
        }

        if (this.permissionService.isRolesEnabled) {
            query.roleId = this.permissionService.activeUserRole?.id;
        }

        if (this.userService.practitioner) {
            query.practitionerId = this.userService.practitioner.id;
        }

        this.iframeUrl = `${this.config.settings && this.config.settings.iframe && this.config.settings.iframe.url}?` + Object.keys(query).map((key) => {
            return `${key}=${encodeURIComponent(query[key])}`;
        }).join('&');
        this.iframeOrigin = this.iframeUrl ? this.iframeUrl.match(/^https?\:\/\/([^\/?#]+)/i)[0] : '';

        if (this.config) {
            BasicForm.pageTitle = this.i18n.tr(this.config.title);
        }

        document.body.classList.add("no-toolbar-window");
        document.body.classList.add("hide-process-arrows");

        window.addEventListener("message", this.iframeListener);
    }
}
